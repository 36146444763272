<template>
  <div>
    <!-- 导航栏 -->
    <defaulttop active="2"></defaulttop>

    <!-- banner区域 -->
    <div class="swipertop flex flex-space">
      <div class="coh1-1 flex flex-centers">
        <img src="../assets/servicesbg1.png" class="aboutbg1img" />
      </div>
      <div class="coh1-1 flex flex-bottom">
        <img src="../assets/servicesbg2.png" class="aboutbg2img" />
      </div>
    </div>

    <!-- 内容区域 -->
    <div class="context flex flex-space">
      <!-- 左侧目录 -->
      <div class="lefttab">
        <div
          @click="bindtab(item.value, index, item)"
          v-for="(item, index) in articleTypes"
          :key="index"
          :class="selectindex == index ? 'lefttitleselect' : 'lefttitle'"
          class="flex flex-centers hovera"
        >
          <span>•</span><span class="ml15">{{ item.label }}</span>
        </div>
      </div>

      <!-- 右侧内容 -->
      <div class="rightcontext" v-if="selectindex < articleTypes.length - 1 && articleList.length > 0">
        <div class="mincontext">
          <div
            class="flex"
            v-for="(item, index) in articleList"
            :key="index"
            @click="btnlistdetails(item.id)"
          >
            <div class="context2">
              <div></div>
            </div>
            <div class="context1">
              <div class="flex">
                <div class="flex ">
                  <div class="txt1 hovera">
                    {{ (item.className?`【${item.className}】`:``) + item.title }}
                  </div>
                  <div class="isnew">NEW</div>
                </div>
                <div class="times">{{item.createTime}}</div>
              </div>
              <div class="txt2 hovera" v-html="item.summary"></div>
            </div>
          </div>
        </div>
        <div class="flex flex-center mt30">
          <el-pagination
            background
            @current-change="getArticleList"
            :current-page.sync="currentPage"
            :page-size="pagesize"
            layout="total, pager ,jumper"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>
      <div class="rightcontext1" v-if="selectindex == articleTypes.length - 1">
        <div class="flex flex-centers mt60">
          <img src="../assets/ai1.png" class="aiicon" />
          <div class="txt4">文字识别</div>
          <div class="txt5">Character recognition</div>
        </div>
        <div class="txt6">
          基于业界领先的深度学习技术，提供对身份证、银行卡等常用卡片及证照的文字内容进行结构化识别的服务
        </div>
        <div class="flex flex-wrap">
          <div class="subset mt30 flex flex-centers hovera" @click="btndetails(0)">
            <span>身份证识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
          <div class="subset mt30 flex flex-centers hovera" @click="btndetails(1)">
            <span>银行卡识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
        </div>
        <div class="flex flex-centers mt60">
          <img src="../assets/ai2.png" class="aiicon" />
          <div class="txt4">目标检测</div>
          <div class="txt5">object detection</div>
        </div>
        <div class="txt6">对图片进行识别，检测与提取图片中的主体目标。</div>
        <div class="flex flex-wrap">
          <div class="subset mt30 flex flex-centers hovera" @click="btndetails(2)">
            <span>人像识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
          <div class="subset mt30 flex flex-centers">
            <span>车辆识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
          <div class="subset mt30 flex flex-centers">
            <span>动物识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
          <div class="subset mt30 flex flex-centers">
            <span>水果识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
          <div class="subset mt30 flex flex-centers">
            <span>家具识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
          <div class="subset mt30 flex flex-centers">
            <span>电器识别</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
        </div>
        <div class="flex flex-centers mt60">
          <img src="../assets/ai3.png" class="aiicon" />
          <div class="txt4">图像处理</div>
          <div class="txt5 ">image processing</div>
        </div>
        <div class="txt6">
          对因浓雾导致细节无法辨认的图像进行去雾处理，还原图像，使其更加清晰可辨。
        </div>
        <div class="flex flex-wrap">
          <div class="subset mt30 flex flex-centers hovera" @click="btndetails(3)">
            <span>去雾处理</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
        </div>
        <div class="flex flex-centers mt60">
          <img src="../assets/ai4.png" class="aiicon" />
          <div class="txt4">自然语言处理</div>
          <div class="txt5">language processing</div>
        </div>
        <div class="txt6">
          根据新闻标题及正文内容，自动提取新闻文本中的关键字。
        </div>
        <div class="flex flex-wrap">
          <div class="subset mt30 flex flex-centers hovera" @click="btndetails(4)">
            <span>新闻摘要提取</span>
            <img src="../assets/righttip.png" class="righttip ml10" />
          </div>
        </div>
      </div>
      <div class="rightcontext" style="padding: 15px" v-if="articleList.length == 0 && selectindex < articleTypes.length - 1">{{loadMsg}}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import defaulttop from "../components/defaulttop";
export default {
  name: "EcologicalServices",
  data() {
    return {
      pid: 1,
      classid: "",
      selectindex: 0,
      articleTypes:[],
      articleList:[],
      loadMsg: "正在加载...",
      totalCount: 0, // 数据总量
      totalPage: 0, // 总页数
      currentPage: 0, // 当前页
      pagesize: 20 //每页条数
    };
  },
  components: {
    defaulttop,
  },
  mounted() {

    if(this.$route.query && this.$route.query.classid){
      this.classid = this.$route.query.classid;
    }

    this.getData();
  },
  methods: {
    ...mapGetters(["getSubMenus"]),
    getData(){
      let list = this.getSubMenus()('services');
      let that = this;
      let loadData = function (){
        list.unshift({ label: "全部", value: 888 });
        that.articleTypes = list;
        if(that.classid == 999){
          that.bindtab(that.classid,that.articleTypes.length-1);
        }else if(that.classid != ''){
          const idx = that.articleTypes.findIndex(item=>{
            return item.value == that.classid;
          });
          if(idx != -1){
            that.selectindex = idx;
          }
          that.bindtab(that.classid, idx);
        }else{
          that.getArticleList(1);
        }
      }

      if(list && list.length > 0){
        loadData();
      }else{
        let timer = setInterval(()=>{
          list = this.getSubMenus()('services');
          if(list && list.length > 0){
            clearInterval(timer);
            loadData();
          }
        }, 500);
      }
    },
    // 切换左边tab
    bindtab(classid, index, item) {
      this.selectindex = index;
      this.classid = classid == 888 ? "" : classid;
      if(this.classid == 999) return;
      this.articleList = [];
      if(index!=-1){
        let _item = this.articleTypes[index];
        if(_item.type==1){
          window.open(_item.link, 'ai.cicba')
        }else{
          this.getArticleList(1);
        }
      }else{
        this.getArticleList(1);
      }
      //this.getArticleList(1);
    },
    // 获取文章列表
    getArticleList(page){
      this.currentPage = page;
      this.loadMsg = '正在加载...';
      this.$http.getArticleListByClassId(this.currentPage, this.pagesize, this.pid, this.classid).then(res=>{
        if(res.code == 0){
          this.totalCount = res.data.totalCount;
          this.totalPage = res.data.totalPage;
          this.articleList.push.apply(this.articleList, res.data.list.map(item=>{
            let dom_div = document.createElement("div");
            dom_div.innerHTML = item.context;
            item.contentText = dom_div.innerText;
            return item;
          }));
          if(this.articleList.length == 0) this.loadMsg = '暂无数据';
        }
      })
    },
    // 列表详情
    btnlistdetails(id) {
      this.$router.push({
        path: "/details",
        query: {
          type: 2,
          articleId: id,
          classid: this.classid
        },
      });
    },
    // 详情
    btndetails(type) {
      this.$router.push({
        path: "/aidetails",
        query: {
          type: type
        },
      });
    },
  },
};
</script>
<style scoped>
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 371px;
  height: 126px;
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 190.2px;
}
.context {
  min-height: calc(100vh - 500px);
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.lefttab {
  width: 10vw;
}
.lefttitle {
  height: 50px;
  color: #999;
  font-size: 16px;
  border-right: 1px solid #e5e4e8;
}
.lefttitleselect {
  height: 50px;
  color: #1a5df1;
  font-size: 16px;
  border-right: 2px solid #205cf4;
  background: linear-gradient(
    90deg,
    #eff3f9 0%,
    rgba(255, 255, 255, 0.98) 100%
  );
}
.rightcontext {
  width:calc(100vw - 37.5vw - 12.1875vw);
  background: #ffffff;
  position: relative;
  padding: 0 30px 50px 30px;
}
.rightcontext1 {
  width: 100%;
  background: #ffffff;
  position: relative;
  padding: 0 0 50px 100px;
}
.context1 {
  height: 154px;
  padding-top: 40px;
}
.txt1 {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.isnew {
  display: none;
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  background: rgba(240, 75, 67, 1);
  border-radius: 10px 10px 10px 0;
  margin-left: 15px;
}
.txt2 {
  word-wrap: break-word;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 400;
  color: #9da3ab;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.mincontext {
  min-height: 770px;
}
.mincontext .context1 {
  border-bottom: 1px solid #e6e6e6;
  width: calc(100% - 40px);
}
.mincontext .context1:before{
  display: table;
  clear: both;
  content: '';
}
.times {
  font-size: 16px;
  color: #9da3ab;
  margin-left: 15px;
}
.context2 {
  width: 40px;
  padding-top: 50px;
}
.context2 div {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 50%;
}
.aiicon {
  width: 31.4px;
  height: 31.4px;
  margin-right: 25px;
}
.txt4 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-right: 25px;
}
.txt5 {
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  color: #333333;
  opacity: 0.05;
}
.txt6 {
  font-size: 16px;
  color: #9da3ab;
  line-height: 30px;
  margin-top: 40px;
}
.subset {
  font-size: 20px;
  font-weight: 400;
  color: #205cf4;
  width: 25%;
}
.f10 {
  font-size: 10px;
}
.righttip{
  width: 10px;
  height: 10px;
}
</style>
